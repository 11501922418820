<template>
  <div>
    <button @click="addUser($event)" type="button" class="btn btn-primary mb-3">
      <i class="fal fa-plus mr-2"></i>Add
    </button>
    <table class="table table-sm table-striped table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Veracity Id</th>
          <th>Company</th>
          <th>Email</th>
          <th>Account</th>
          <th>Role</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="isLoading">
        <tr>
          <td id="loadingImageTableCell" colspan="6">
            <img src="../assets/ajax-loader.gif" alt="Loading" />
          </td>
        </tr>
      </tbody>
      <tbody v-if="!isLoading">
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.name }}</td>
          <td>{{ user.id }}</td>
          <td>{{ user.company }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.account }}</td>
          <td>{{ roleDescription(user.role) }}</td>
          <td>
            <button
              @click="deleteUser(user)"
              type="button"
              class="btn btn-subtle btn-xs"
            >
              <i class="fal fa-minus mr-2"></i>Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="addModalVisible"
      class="modal-backdrop fade show"
      style="position: absolute; height: 100%; width: calc(100% - 0px)"
    ></div>
    <div
      class="modal fade show"
      id="addUserModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addUserModal"
      aria-hidden="true"
      style="display: block; position: relative"
    >
      <add-user v-if="addModalVisible" @close="closeModal"></add-user>
    </div>
  </div>
</template>

<script>
import { dataService } from "../shared/data.service";
import AddUser from "./AddUser.vue";
import { roles } from "../shared/roles";

export default {
  name: "Users",
  data() {
    return {
      users: null,
      addModalVisible: false,
    };
  },
  components: { AddUser },
  async created() {
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      await dataService.getUsers().then((data) => (this.users = data));
    },
    async addUser(event) {
      var addUserModal = document.getElementById("addUserModal");
      addUserModal.style.top = event.target.getBoundingClientRect().top + "px";
      addUserModal.style.position = "absolute";

      this.addModalVisible = true;
    },
    async deleteUser(user) {
      this.$confirm({
        message: `Would you like to delete '${user.name}'?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            dataService.deleteUser(user.id).then(() => this.loadUsers());
          }
        },
      });
    },
    closeModal(refreshRequired) {
      this.addModalVisible = false;
      var addUserModal = document.getElementById("addUserModal");
      addUserModal.style.position = "relative";

      if (refreshRequired) {
        this.users = null;
        this.loadUsers();
      }
    },
    roleDescription(roleId) {
      var roleDescription = roles[roleId];

      if (!roleDescription) {
        roleDescription = "Invalid role";
      }

      return roleDescription;
    },
  },
  computed: {
    isLoading() {
      return !this.users;
    },
  },
};
</script>
          
      