<template>
<footer role="contentinfo" class="footer small mt-4">
  <div class="footer-small container-fluid">
    <ul class="footer-small-links">
      <li>
        <a
          target="_self"
          href="https://www.dnv.com/software/software-services/software-support.html"
          class=""
        >
          Contact support
        </a>
      </li>
      <li>
        <a target="_self" href="/news" class="">
          What's new
        </a>
      </li>
      <li>
        <a
          target="_self"
          href="https://www.dnv.com/software/video-webinar/index.html"
          class=""
        >
          Videos and webinars
        </a>
      </li>
    </ul>
    <div class="footer-small-dnvgl">
      <div class="my-3 mr-3 my-sm-0 mr-sm-0 title-spacing">
        © DNV 2021
      </div>
      <span class="mx-3 d-none d-sm-inline">
        |
      </span>
      <ul class="footer-some">
        <li class="mr-2">
          <a
            target="_self"
            href="https://www.linkedin.com/showcase/dnvgl-software/"
            class="some-circle small"
            ><i class="fab fa-linkedin-in"></i
          ></a>
        </li>
        <li class="mr-2">
          <a
            target="_self"
            href="https://www.facebook.com/dnvglsoftware/"
            class="some-facebook small"
            ><i class="fab fa-facebook"></i
          ></a>
        </li>
        <li>
          <a
            target="_self"
            href="https://www.youtube.com/user/dnvsoftware"
            class="some-circle some-youtube small"
            ><i class="fab fa-youtube"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
</footer>
</template>

<script>

export default {
  name: "Footer"
};
</script>
