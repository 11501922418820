import Oidc from 'oidc-client';

const createUserManager = function (veracityClientId) {
    var userManager = new Oidc.UserManager({
        authority: 'https://login.veracity.com/tfp/dnvglb2cprod.onmicrosoft.com/B2C_1A_SignInWithADFSIdp/v2.0/',
        client_id: veracityClientId,
        redirect_uri: `${window.location.origin}${process.env.VUE_APP_REDIRECT_URI}`,
        response_type: 'id_token token',
        scope: 'openid offline_access https://dnvglb2cprod.onmicrosoft.com/83054ebf-1d7b-43f5-82ad-b2bde84d7b75/user_impersonation',
        post_logout_redirect_uri: 'https://www.veracity.com/auth/logout', // TODO - Clear all local session data for your application
        userStore: new Oidc.WebStorageStateStore(),
        loadUserInfo: false,
        extraQueryParams: { "mfa_required": "true" }
    });

    return userManager;
};

export const security = {
    createUserManager
};