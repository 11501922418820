<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Edit Account</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="cancel"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div v-if="isBusy" class="modal-body">
        <img src="../assets/ajax-loader.gif" alt="Loading" />
      </div>

      <div v-if="!isBusy" class="modal-body">
        <div class="form-group">
          <label for="company">Company</label>
          <input
            type="text"
            id="company"
            class="form-control"
            v-model="account.company"
            v-bind:class="{ 'is-invalid': $v.account.company.$error }"
          />
          <div class="invalid-feedback" v-if="$v.account.company.$error">
            {{ $v.account.company.$errors[0].$message }}
          </div>
        </div>
        <div class="form-group">
          <label for="accountId">Account Id:</label>
          <span> {{ this.account.id }}</span>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="enabled"
              class="custom-control-input"
              v-model="account.enabled"
            />
            <label class="custom-control-label" for="enabled">Enabled</label>
          </div>
        </div>
        <div class="form-group" id="services">
          <label for="services">Services</label>
          <div
            v-for="service in services"
            :key="service.id"
            class="custom-control custom-checkbox"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :value="service.id"
              :id="service.id"
              v-model="account.services"
            />
            <label class="custom-control-label" :for="service.id"
              >{{ service.description }} ({{ service.name }})</label
            >
          </div>
        </div>
        <div id="accordion" class="accordion">
          <div
            class="card"
            v-for="token in this.accessTokens"
            :key="token.type"
          >
            <div
              class="card-header"
              v-bind:id="`AccordionPanel${token.name}`"
              data-toggle="collapse"
              v-bind:href="`#collapse${token.name}`"
              aria-expanded="true"
              aria-controls="collapsePrimary"
            >
              <p class="card-title">{{ token.name }} access token</p>
            </div>
            <div
              v-bind:id="`collapse${token.name}`"
              class="collapse"
              v-bind:aria-labelledby="`heading${token.name}`"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div id="apiKeyExpiry" class="mb-3">
                <span> Expires: {{ token.expiryDate | formatDateTime }} </span>
                </div>
                <div v-bind:id="'apiKeyDiv_'+token.type" class="mb-3">{{ token.token ? token.token : null }}</div>
                <button
                  @click="copyTokenToClipboard(token.type)"
                  type="button"
                  class="btn btn-primary mr-2"
                >
                  <i class="fal fa-copy mr-2"></i>Copy
                </button>
                <button
                  @click="generateNewToken(token.type)"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fal fa-redo mr-2"></i>Generate
                </button>
              </div>            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-subtle"
          data-dismiss="modal"
          @click="cancel()"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="save()">
          <i class="fal fa-save mr-2"></i>Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength } from "@vuelidate/validators";
import { dataService } from "../shared/data.service";
import Vue from "vue";

export default {
  name: "EditAccount",
  setup () {
    return { $v: useVuelidate() }
  },
  props: {
    accountId: String,
  },
  data() {
    return {
      account: null,
      services: [],
      accessTokens: [],
      isSaving: false,
    };
  },
  async created() {
    dataService.getServices().then((data) => {
      this.services = data.services;
    });

    if (this.accountId) {
      this.account = dataService.getAccount(this.accountId).then((data) => {
        this.account = data.account;
        this.accessTokens = data.accessTokens;
      });
    }
  },
  computed: {
    isBusy() {
      return !this.account || this.isSaving;
    },
  },
  methods: {
    async generateNewToken(type) {
      this.$confirm({
        message: `Would you like to generate a new token for '${this.account.company}'? This will replace and revoke the current token.`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            dataService
              .generateNewToken(
                type,
                this.account.id,
                this.$store.state.user.profile.userId
              )
              .then((data) => {
                this.accessTokens = data.accessTokens;
                Vue.notify({
                  text: `New token generated.`,
                  group: "success",
                  closeOnClick: "true",
                });
              });
          }
        },
      });
    },
    copyTokenToClipboard(type) {
      var range = document.createRange();
      range.selectNode(document.getElementById("apiKeyDiv_"+type));
      window.getSelection().removeAllRanges(); // Clear current selection
      window.getSelection().addRange(range); // Add selected text
      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      Vue.notify({
        text: `Copied to clipboard`,
        group: "success",
        closeOnClick: "true",
      });
    },
    cancel() {
      // Close modal, do not reload parent page
      this.$emit("close", false);
    },
    async save() {
      this.isSaving = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await dataService.putAccount(this.account);
        // Close modal, reload parent page
        this.$emit("close", true);
      }

      this.isSaving = false;
    },
  },
  validations: {
    account: {
      company: {
        maxLength: maxLength(255),
        required,
      },
    },
  },
};
</script>
