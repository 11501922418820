<template>
  <header class="o-header">
    <nav class="o-header-nav">
      <a href="#" class="logo logo-sm" target="_self">
        <img
          src="../assets/DNV_logo_RGB.svg"
          alt="DNV Logo"
          class="logo-image"
        />
      </a>
      <ul class="o-header-links">
        <li>
          <button
            aria-label="Close menu"
            class="o-header-dropdown-close"
            onclick="toggleMenu()"
          >
            <i class="fal fa-times"></i>
          </button>
        </li>
        <li id="Home" class="o-header-item">
          <router-link active-class="active" class="o-header-link" to="/Home"
            >Home</router-link
          >
        </li>
        <li id="Documents" class="o-header-item">
          <router-link active-class="active" class="o-header-link" to="/documents"
            >Documents</router-link
          >
        </li>
        <li id="Accounts" v-show="isAdmin()" class="o-header-item">
          <router-link active-class="active" class="o-header-link" to="/accounts"
            >Accounts</router-link
          >
        </li>
        <li id="Users" v-show="isAdmin()" class="o-header-item">
          <router-link active-class="active" class="o-header-link" to="/users"
            >Users</router-link
          >
        </li>
      </ul>
      <div class="o-header-right">
        <a href="/" class="o-header-product">WindFarmer Services</a>
        <ul class="o-header-actions">
          <li class="o-header-action">
            <a target="_self" href="#" class="o-header-action-btn">
              <i class="fal fa-question-circle"></i>
            </a>
          </li>
          <li v-show="!isAuthenticated()" class="o-header-action">
            <a
              target="_self"
              href="#"
              @click="logIn()"
              class="o-header-action-btn w-auto"
            >
              Login
            </a>
          </li>
          <li
            v-show="isAuthenticated()"
            class="o-header-dropdown o-header-dropdown-user"
          >
            <a
              aria-haspopup="true"
              aria-expanded="false"
              target="_self"
              href="#"
              class="o-header-action-btn"
              id="header-user-dropdown"
              @click="toggleDropdown()"
            >
              <section class="o-header-user">
                <p>{{ userInitials }}</p>
              </section>
            </a>
            <ul
              tabindex="-1"
              id="header-user-dropdown-menu"
              class="o-header-dropdown-menu"
              aria-labelledby="header-user-dropdown"
            >
              <li>
                <button
                  aria-label="Close menu"
                  id="o-header-usermenu-close"
                  class="o-header-dropdown-close"
                >
                  <i class="fal fa-times"></i>
                </button>
              </li>
              <li class="o-header-dropdown-menu-item">
                <header role="heading" class="o-header-dropdown-heading">
                  Your profile
                </header>
              </li>
              <li class="o-header-dropdown-menu-item">
                <span class="text-bold">{{ username }}</span>
              </li>
              <li>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="o-header-dropdown-divider"
                />
              </li>
              <li class="o-header-dropdown-menu-item">
                <header role="heading" class="o-header-dropdown-heading">
                  Manage account
                </header>
              </li>
              <li class="o-header-dropdown-menu-item">
                <a
                  role="menuitem"
                  href="https://services.veracity.com/EditProfile"
                  target="_blank"
                  class="active"
                >
                  Settings
                </a>
              </li>
              <li class="o-header-dropdown-menu-item">
                <a role="menuitem" href="#" @click="logOut()"> Log out </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  async created() {
    // Close user menu when clicking outside
    window.addEventListener("click", function (e) {
      const userMenu = document.getElementById("header-user-dropdown-menu");
      const userToggle = document.getElementById("header-user-dropdown");
      if (
        !userMenu.contains(e.target) &&
        !userToggle.contains(e.target) &&
        userMenu.classList.contains("show")
      ) {
        // Clicked in box
        document
          .getElementById("header-user-dropdown-menu")
          .classList.toggle("show");
      }
    });
  },
  methods: {
    // Handle toggle for user dropdown
    async toggleDropdown() {
      document
        .getElementById("header-user-dropdown-menu")
        .classList.toggle("show");
    },
    async logIn() {
      await this.$store.state.mgr.signinRedirect();
    },
    async logOut() {
      await this.$store.state.mgr.signoutRedirect();
    },
  },
  computed: {
    // Current logged in user's name
    username() {
      const currentUser = this.$store.state.user;

      let username = "";

      if (currentUser && currentUser.profile) {
        username = currentUser.profile.name;
      }

      return username;
    },
    // Current logged in user's initials
    userInitials() {
      const currentUser = this.$store.state.user;

      let initials = "";

      if (currentUser && currentUser.profile) {
        initials =
          currentUser.profile.given_name.substring(0, 1) +
          currentUser.profile.family_name.substring(0, 1);
      }

      return initials;
    },
  },
};
</script>
