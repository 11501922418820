<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Add Account</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="cancel"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div v-if="isBusy" class="modal-body">
        <img src="../assets/ajax-loader.gif" alt="Loading" />
      </div>

      <div v-if="!isBusy" class="modal-body">
        <div class="form-group">
          <label for="company">Company</label>
          <input
            type="text"
            id="company"
            class="form-control"
            v-model="account.company"
            v-bind:class="{ 'is-invalid': $v.account.company.$error }"
          />
          <div class="invalid-feedback" v-if="$v.account.company.$error">
            {{ $v.account.company.$errors[0].$message }}
          </div>
        </div>
        <div class="form-group">
          <label for="accountId">Account Id</label>
          <input
            type="text"
            id="accountId"
            class="form-control"
            v-model="account.id"
            v-bind:class="{ 'is-invalid': $v.account.id.$error }"
          />
          <div class="invalid-feedback" v-if="$v.account.id.$error">
            {{ $v.account.id.$errors[0].$message }}
          </div>
        </div>
        <div class="form-group" id="services">
          <label for="services">Services</label>
          <div
            v-for="service in services"
            :key="service.id"
            class="custom-control custom-checkbox"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :value="service.id"
              :id="service.id"
              v-model="account.services"
            />
            <label class="custom-control-label" :for="service.id"
              >{{ service.description }} ({{ service.name }})</label
            >
          </div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="enabled"
              class="custom-control-input"
              v-model="account.enabled"
            />
            <label class="custom-control-label" for="enabled">Enabled</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-subtle"
          data-dismiss="modal"
          @click="cancel()"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="save()">
          <i class="fal fa-save mr-2"></i>Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength } from "@vuelidate/validators";
import { dataService } from "../shared/data.service";

class Account {
  constructor(id, company, enabled, services, accessTokens) {
    this.id = id;
    this.company = company;
    this.enabled = enabled;
    this.services = services;
    this.accessTokens = accessTokens;
  }
}

export default {
  name: "AddAccount",
  setup () {
    return { $v: useVuelidate() }
  },
  data() {
    return {
      account: null,
      services: [],
      accessTokenTypes: [],
      isSaving: false,
    };
  },
  async created() {
    this.account = new Account("", "", true, [], []);
    await dataService.getServices().then((data) => {
      this.services = data.services;
    });
  },
  computed: {
    isBusy() {
      return !this.account || this.isSaving;
    },
  },
  methods: {
    cancel() {
      // Close modal, do not reload parent page
      this.$emit("close", false);
    },
    async save() {
      this.isSaving = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await dataService.postAccount(this.account);
        // Close modal, reload parent page
        this.$emit("close", true);
      }

      this.isSaving = false;
    },
  },
  validations: {
    account: {
      company: {
        maxLength: maxLength(255),
        required,
      },
      id: {
        required,
      },
    },
  },
};
</script>
