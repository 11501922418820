<template>
  <div>
    <button
      v-show="isAuthenticated() && isAdmin()"
      @click="addDocument($event)"
      type="button"
      class="btn btn-primary mb-3">
      <i class="fal fa-plus mr-2"></i>Add
    </button>
    <div
      v-if="addModalVisible"
      class="modal-backdrop fade show"
      style="position: absolute; height: 100%; width: calc(100% - 0px)">
    </div>
    <div
      class="modal fade show"
      id="addDocumentModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addDocumentModal"
      aria-hidden="true"
      style="display: block; position: relative">
        <add-document v-if="addModalVisible" @close="closeModal"></add-document>
    </div>

      <div v-show="isAuthenticated()">
        <div id="accordion" class="accordion">
          <div
            class="card"
            v-for="documentGroup in this.documents" :key="documentGroup.id"
            >

            <div
              class="card-header"
              v-bind:id="`AccordionPanel${documentGroup.id}`"
              data-toggle="collapse"
              v-bind:href="`#collapse${documentGroup.id}`"
              aria-expanded="false"
              aria-controls="collapsePrimary"
            >
              <p class="card-title">Version: {{documentGroup.version}} Released: {{documentGroup.releaseDate | formatDate}}</p>
            </div>
            
            <div
              v-bind:id="`collapse${documentGroup.id}`"
              class="collapse"
              v-bind:aria-labelledby="`heading${documentGroup.version}`"
              data-parent="#accordion">

              <div class="card-body">
                <ul class="list-group list-group-flush" id="documentSelect">
                  <li v-for="documentFile in documentGroup.documents" :key="documentFile.id">
                    <a href="#" @click="downloadFile(documentFile)">{{documentFile.filename}}</a>
                    <button v-show="isAuthenticated() && isAdmin()"
                    @click="deleteDocument(documentFile.id)"
                    type="button"
                    class="btn btn-flat sb-3">
                    <i class="fal fa-minus mr-2"></i>Delete</button>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import { dataService } from "../shared/data.service";
import AddDocument from "./AddDocument.vue";

export default {
  name: "Documents",
  data() {
    return {
      documents: null,
      addModalVisible: false
    };
  },
  components: { AddDocument },
  async created() {
    this.loadDocuments();
  },
  methods: {
    async loadDocuments() {
      await dataService.getDocuments().then((data) => {
        this.documents = data.documents;
      });
    },
    async downloadFile(documentFile)
    {
      await dataService.getDocument(documentFile.id).then((data) => {
        var fileUrl = window.URL.createObjectURL(new Blob([data], { type: "application/octet-stream" }));
        var fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.download = documentFile.filename;
        document.body.appendChild(fileLink);
        fileLink.click();
        URL.revokeObjectURL(fileUrl.href);
        document.body.removeChild(fileLink);
      });
    },
    async addDocument(event) {
      var addDocumentModal = document.getElementById("addDocumentModal");
      addDocumentModal.style.top =
        event.target.getBoundingClientRect().top + "px";
      addDocumentModal.style.position = "absolute";

      this.addModalVisible = true;
    },
    async deleteDocument(documentId) {
      await dataService.deleteDocument(documentId);
      this.docuents = null;
      this.loadDocuments();
    },
    closeModal(refreshRequired) {
      this.addModalVisible = false;

      var addDocumentModal = document.getElementById("addDocumentModal");
      addDocumentModal.style.position = "relative";

      if (refreshRequired) {
        this.docuents = null;
        this.loadDocuments();
      }
    },
  },
  computed: {
  },
};
</script>