import axios from 'axios';
import store from '../store/index';
import Vue from 'vue';

axios.interceptors.request.use(
    // Add auth token to each controller call
    async (request) => {
      if (!store) return;
  
      const user = await store.state.mgr.getUser();
  
      if (!user) return;
  
      // We are calling the web backend so add the access token and csrf token
      if (request.url.startsWith(`${process.env.VUE_APP_BACKEND_URL}`)) {
        const authToken = user.access_token;
        if (authToken) {
          request.headers.Authorization = `Bearer ${authToken}`;
          }
       }
  
      return request;
    });
  
  axios.interceptors.response.use(
    // Successful response so do nothing
    response => response,
    error => {
      Vue.notify({
        text: error.response.data.title,
        group: 'error',
        closeOnClick: "true",
      });
  
      throw error;
    }
  );

  const getUserRole = async function (id) {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/role/${id}`);
    return response.data;
  };

  const getUser = async function () {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/user`);
    return response.data;
  };

  const getServices = async function () {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/services`);
    return response.data;
  };

  const getAccounts = async function () {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/accounts`);
    return response.data;
  };

  const getAccount = async function (accountId) {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/account/${accountId}`);
    return response.data;
  };

  const postAccount = async function (account) {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/account`, account);
    return response.data;
  };

  const putAccount = async function (account) {
    const response = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/account/${account.id}`, account);
    return response.data;
  };

  const getAccessTokenTypes = async function () {
    const response = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/accesstokentype`);
    return response.data;
  };

  const generateNewToken = async function (type, account, user) {
    const response = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/accesstoken?type=${type}&account=${account}&user=${user}`);
    return response.data;
  };

  const getUsers = async function () {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/users`);
    return response.data;
  };

  const postUser = async function (user) {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/user`, user);
    return response.data;
  };

  const deleteUser = async function (userId) {
    const response = await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/user/${userId}`);
    return response.data;
  };

  const getDocumentTypes = async function () {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/documenttype`);
    return response.data;
  };

  const getDocuments = async function() {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/document`);
    return response.data;
  };

  const getDocument = async function(documentId) {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/download/${documentId}`, {responseType: 'blob'});
    return response.data;
  };

  const deleteDocument = async function(documentId) {
    const response = await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/document/${documentId}`);
    return response.data;
  };

  const postDocument = async function (formData) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/document`, formData, { headers });
    return response.data;
  };

  export const dataService = {
    getUserRole,
    getUser,
    getServices,
    getAccounts,
    getAccount,
    postAccount,
    putAccount,
    getAccessTokenTypes,
    generateNewToken,
    getUsers,
    postUser,
    deleteUser,
    getDocumentTypes,
    getDocuments,
    getDocument,
    deleteDocument,
    postDocument
  };