<template>
  <div>
    <button
      @click="addAccount($event)"
      type="button"
      class="btn btn-primary mb-3"
    >
      <i class="fal fa-plus mr-2"></i>Add
    </button>
    <table class="table table-sm table-striped table-hover">
      <thead>
        <tr>
          <th>Company</th>
          <th>Account</th>
          <th>Enabled</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="isLoading">
        <tr>
          <td id="loadingImageTableCell" colspan="3">
            <img src="../assets/ajax-loader.gif" alt="Loading" />
          </td>
        </tr>
      </tbody>
      <tbody v-if="!isLoading">
        <tr v-for="account in accounts" :key="account.id">
          <td>{{ account.company }}</td>
          <td>{{ account.id }}</td>
          <td>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="accountEnable"
                class="form-check-input"
                v-model="account.enabled"
                @change="enableAccount(account)"
              />
            </div>
          </td>
          <td class="d-flex justify-content-end">
            <button
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              class="dropdown-toggle btn btn-subtle"
            >
              Actions
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item"
                href="#"
                @click="editAccount(account, $event)"
                >Edit</a
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="addModalVisible || editModalVisible"
      class="modal-backdrop fade show"
      style="position: absolute; height: 100%; width: calc(100% - 0px)"
    ></div>
    <div
      class="modal fade show"
      id="addAccountModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addAccountModal"
      aria-hidden="true"
      style="display: block; position: relative"
    >
      <add-account v-if="addModalVisible" @close="closeModal"></add-account>
    </div>
    <div
      class="modal fade show"
      id="editAccountModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editAccountModal"
      aria-hidden="true"
      style="display: block; position: relative"
    >
      <edit-account
        v-if="editModalVisible"
        :accountId="selectedAccountId"
        @close="closeModal"
      ></edit-account>
    </div>
  </div>
</template>

<script>
import { dataService } from "../shared/data.service";
import AddAccount from "./AddAccount.vue";
import EditAccount from "./EditAccount.vue";

export default {
  name: "Accounts",
  data() {
    return {
      accounts: null,
      users: null,
      selectedAccountId: "",
      addModalVisible: false,
      editModalVisible: false,
    };
  },
  components: { AddAccount, EditAccount },
  async created() {
    this.loadAccounts();
  },
  methods: {
    async loadAccounts() {
      await dataService.getAccounts().then((data) => {
        this.accounts = data.accounts;
      });
    },
    async enableAccount(account) {
      await dataService.putAccount(account);
    },
    async addAccount(event) {
      var addAccountModal = document.getElementById("addAccountModal");
      addAccountModal.style.top =
        event.target.getBoundingClientRect().top + "px";
      addAccountModal.style.position = "absolute";

      this.addModalVisible = true;
    },
    async editAccount(selectedAccount, event) {
      if (selectedAccount) {
        this.selectedAccountId = selectedAccount.id;
      }
      var editAccountModal = document.getElementById("editAccountModal");
      editAccountModal.style.top =
        event.target.getBoundingClientRect().top + "px";
      editAccountModal.style.position = "absolute";

      this.editModalVisible = true;
    },
    closeModal(refreshRequired) {
      this.addModalVisible = false;
      this.editModalVisible = false;

      var addAccountModal = document.getElementById("addAccountModal");
      addAccountModal.style.position = "relative";

      var editAccountModal = document.getElementById("editAccountModal");
      editAccountModal.style.position = "relative";

      if (refreshRequired) {
        this.accounts = null;
        this.loadAccounts();
      }
    },
  },
  computed: {
    isLoading() {
      return !this.accounts;
    },
  },
};
</script>