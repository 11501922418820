<template>
  <div class="container">
    <h1>Welcome to the WindFarmer Services Portal</h1>
    <div v-show="isAuthenticated()" class="row mb-3">
      <div class="col-6">
        <p>Hello {{ username }},</p>
        <p>
          {{ this.company }} is currently subscribed to the following service/s:
        </p>
        <ul class="list">
          <li v-for="service in this.subscribedServices" :key="service.id">
            {{ service.description }} - ({{ service.name }})
          </li>
        </ul>
        <br />

        <div id="accordion" class="accordion">
          <div
            class="card"
            v-for="token in this.accessTokens"
            :key="token.type"
          >
            <div
              class="card-header"
              v-bind:id="`AccordionPanel${token.name}`"
              data-toggle="collapse"
              v-bind:href="`#collapse${token.name}`"
              aria-expanded="false"
              aria-controls="collapsePrimary"
            >
              <p class="card-title">{{ token.name }} access token</p>
            </div>
            <div
              v-bind:id="`collapse${token.name}`"
              class="collapse"
              v-bind:aria-labelledby="`heading${token.name}`"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div id="apiKeyExpiry" class="mb-3">
                <span> Expires: {{ token.expiryDate | formatDateTime }} </span>
                </div>
                <div v-bind:id="'apiKeyDiv_'+token.type" class="mb-3">{{ token.token ? token.token : null }}</div>
                <button
                  @click="copyTokenToClipboard(token.type)"
                  type="button"
                  class="btn btn-primary mr-2"
                >
                  <i class="fal fa-copy mr-2"></i>Copy
                </button>
                <button
                  @click="generateNewToken(token.type)"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fal fa-redo mr-2"></i>Generate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { dataService } from "../shared/data.service";
import Vue from "vue";

export default {
  name: "Home",
  data() {
    return {
      company: "",
      account: null,
      subscribedServices: null,
      accessTokens: null,
    };
  },
  async created() {
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      await dataService
        .getUser()
        .then(
          (data) => (
            (this.company = data.company),
            (this.account = data.account),
            (this.subscribedServices = data.services),
            (this.accessTokens = data.accessTokens)
          )
        );
    },
    async generateNewToken(type) {
      this.$confirm({
        message: `Would you like to generate a new token for '${this.company}'? This will replace and revoke the current token.`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            dataService
              .generateNewToken(
                type,
                this.account,
                this.$store.state.user.profile.userId
              )
              .then((data) => {
                this.accessTokens = data.accessTokens;
                Vue.notify({
                  text: `New token generated.`,
                  group: "success",
                  closeOnClick: "true",
                });
              });
          }
        },
      });
    },
    copyTokenToClipboard(type) {
      var range = document.createRange();
      range.selectNode(document.getElementById("apiKeyDiv_"+type));
      window.getSelection().removeAllRanges(); // Clear current selection
      window.getSelection().addRange(range); // Add selected text
      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      Vue.notify({
        text: `Copied to clipboard`,
        group: "success",
        closeOnClick: "true",
      });
    },
  },
  computed: {
    username() {
      const currentUser = this.$store.state.user;

      let username = "";
      if (currentUser && currentUser.profile) {
        username = `${currentUser.profile.given_name} ${currentUser.profile.family_name}`;
      }

      return username;
    },
  },
};
</script>
