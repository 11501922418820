<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Add User</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="cancel"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div v-if="isBusy" class="modal-body">
        <img src="../assets/ajax-loader.gif" alt="Loading" />
      </div>

      <div v-if="!isBusy" class="modal-body">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="text"
            id="email"
            class="form-control"
            v-model="user.email"
            v-bind:class="{ 'is-invalid': $v.user.email.$error }"
          />
          <div class="invalid-feedback" v-if="$v.user.email.$error">
            {{ $v.user.email.$errors[0].$message }}
          </div>
        </div>
        <div class="form-group">
          <label for="account">Account</label>
          <select
            v-model="user.account"
            v-bind:class="{ 'is-invalid': $v.user.account.$error }"
            class="form-control"
            id="account"
          >
            <option value="">-- Select Account --</option>
            <option
              v-for="account in accounts"
              :value="account.id"
              :key="account.id"
            >
              {{ account.company }}
            </option>
          </select>

          <div class="invalid-feedback" v-if="$v.user.account.$error">
            {{ $v.user.account.$errors[0].$message }}
          </div>
        </div>
        <div class="form-group">
          <label for="name">Role</label>
          <select
            id="role"
            class="form-control"
            v-model="user.role"
            v-bind:class="{ 'is-invalid': $v.user.role.$error }"
            :disabled="isCurrentUser"
          >
            <option value="0">-- Select User Role --</option>
            <option
              v-for="(role, index) in this.roles"
              :value="index"
              :key="index"
            >
              {{ role }}
            </option>
          </select>
          <div class="invalid-feedback" v-if="$v.user.role.$error">
            {{ $v.user.role.$errors[0].$message }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-subtle"
          data-dismiss="modal"
          @click="cancel()"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="save()">
          <i class="fal fa-save mr-2"></i>Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, email, helpers } from "@vuelidate/validators";
import { dataService } from "../shared/data.service";
import { roles } from "../shared/roles";

function adminMustBeDnv(value) {
  var isAdminAccount = parseInt(value) === 1;
  var isDnvEmail = this.user.email.toString().toLowerCase().endsWith("dnv.com");
  return isDnvEmail || !isAdminAccount;
}

const greaterThanZero = (value) => value > 0;

class User {
  constructor(email, account, role) {
    this.email = email;
    this.account = account;
    this.role = role;
  }
}

export default {
  name: "AddUser",
  setup () {
    return { $v: useVuelidate() }
  },
  data() {
    return {
      user: null,
      accounts: [],
      roles: roles,
      isSaving: false,
    };
  },
  async created() {
    this.user = new User("", "", 0);
    await dataService.getAccounts().then((data) => {
      this.accounts = data.accounts;
    });
  },
  computed: {
    isBusy() {
      return !this.user || this.isSaving;
    },
  },
  methods: {
    cancel() {
      // Close modal, do not reload parent page
      this.$emit("close", false);
    },
    async save() {
      this.isSaving = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await dataService.postUser(this.user);
        // Close modal, reload parent page
        this.$emit("close", true);
      }

      this.isSaving = false;
    },
  },
  validations: {
    user: {
      email: {
        maxLength: maxLength(256),
        required,
        email: helpers.withMessage('Invalid email address', email),
      },
      account: {
        required,
      },
      role: {
        required,
        minValue: helpers.withMessage('Value is required', greaterThanZero),
        adminMustBeDnv: helpers.withMessage('Only users with a DNV email address can be assigned the admin role', adminMustBeDnv)
      },
    },
  },
};
</script>