import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/index.js';
import { dataService } from "./shared/data.service";
import Home from './views/Home.vue'
import Documents from './views/Documents.vue'
import Accounts from './views/Accounts.vue'
import Users from './views/Users.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/documents',
        name: 'Documents',
        component: Documents,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounts',
        name: 'Accounts',
        component: Accounts,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/callback',
        name: 'callback',
        component: () => import(/* webpackChunkName: "bundle.client" */ './views/Callback.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

router.beforeEach(async (to, from, next) => {
    let user = store.state.user;

    if (user == null) {
        // Retrieve the user from the store
        user = await store.state.mgr.getUser();

        if (user != null) {
            await store.dispatch('setUserAction', user);
        }
    }

    let isAuthenticated = (user != null && !user.expired);

    if (to.path.startsWith('/callback')) {
        // Call returned from Veracity auth
        store.state.mgr.signinRedirectCallback().then(
            function (user) {
                var returnToUrl = "/";

                if (user) {
                    dataService.getUserRole(user.profile.userId).then((data) => {
                        // Add role to user's profile. This needs to be a separate call as Veracity doesn't handle roles
                        user.profile.role = data.roleId;
                        store.state.mgr.storeUser(user);

                        // Save user details
                        store.dispatch('setUserAction', user).then(() => {
                            if (user.state !== undefined) {
                                returnToUrl = user.state;
                            }

                            next(returnToUrl);
                        });
                    });
                }
            }
        );
    } else if (isAuthenticated) {
        // Already signed in, we can navigate anywhere
        next();
    } else {
        // Redirect user to sign in
        store.state.mgr.signinRedirect({ state: to.path });
    }
});
