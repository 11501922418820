<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Add Document</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="cancel"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div v-if="isBusy" class="modal-body">
        <img src="../assets/ajax-loader.gif" alt="Loading" />
      </div>

      <div v-if="!isBusy" class="modal-body">
        <div class="form-group">
          <label for="documentType">Document Type</label>
          <div>
          <select v-model="document.type" class="form-control" @change="selectDocType($event)">
              <option v-for="documentType in documentTypes" v-bind:key="documentType.id" :value="documentType.id">
                  {{documentType.name}} 
              </option>
          </select>
        </div>
        </div>
        <div class="form-group">
          <label for="version">Version</label>
          <input
            type="text"
            id="version"
            class="form-control"
            v-model="document.version"
            v-bind:class="{ 'is-invalid': $v.document.version.$error }"
          />
          <div class="invalid-feedback" v-if="$v.document.version.$error">
            {{ $v.document.version.$errors[0].$message }}
          </div>
        </div>
        <div class="form-group">
          <label for="releaseDate">Release Date</label>
          <div class="input-group mb-3">
            <input
             id="releaseDate"
             type="date" 
             class="form-control" 
             v-model="document.releaseDate"/>
          </div>   
        </div>
        <div class="form-group">
          <input type="file" @change="uploadFile" ref="file" style="display: none" v-bind:accept=this.uploadType>
          <button id="selectedfile" class="btn btn-cta" @click="handleFileSelect">Select File</button>
          <div v-show="displayFilename">Selected file: {{this.selectedFilename}}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-subtle"
          data-dismiss="modal"
          @click="cancel()"
        >Cancel</button>
        <button type="button" class="btn btn-primary" @click="submitFile()">Upload</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { dataService } from "../shared/data.service";
import { useVuelidate } from '@vuelidate/core';
import { required } from "@vuelidate/validators";

class Document {
  constructor(version, documentType, releaseDate, filename) {
    this.version = version;
    this.type = documentType;
    this.releaseDate = releaseDate;
    this.filename = filename;
  }
}

export default {
  name: "AddDocument",
  setup () {
    return { $v: useVuelidate() }
  },
  data() {
    return {
      document: null,
      documentTypes: [],
      isSaving: false,
      displayFilename: false,
      selectedFilename: "",
      uploadType: ".json",
    };
  },
  async created() {
    this.document = new Document("", 1, moment().toISOString, "");
    await dataService.getDocumentTypes().then((data) => {
      this.documentTypes = data.documentTypes;
    });
  },
  computed: {
    isBusy() {
      return !this.document || this.isSaving;
    }
  },
  methods: {
    handleFileSelect() {
      this.$refs.file.click();
    },
    uploadFile() {
      this.Documents = this.$refs.file.files[0];
      this.selectedFilename = this.$refs.file.files[0].name;
      this.displayFilename = true;
    },
    async submitFile() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSaving = true;
        const formData = new FormData();
        formData.append('document', JSON.stringify(this.document));
        formData.append('files', this.Documents);
        await dataService.postDocument(formData);
        this.isSaving = false;
        this.$emit("close", true);
      }
    },
    selectDocType(event) {
      if (event.target.value == 1) {
        this.uploadType = ".json";
      } else if (event.target.value == 2) {
        this.uploadType = ".html";
      } else if (event.target.value == 3) {
        this.uploadType = ".pdf";
      }
    },
    cancel() {
      this.$emit("close", false);
    }
  },
    validations: {
    document: {
      version: {
        required,
      },
    },
  },
};
</script>